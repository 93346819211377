import './index.scss';

import { ChakraProvider } from '@chakra-ui/react';
import Amplify from 'aws-amplify';
import React, { useEffect } from 'react';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import awsconfig from './amplify-config';
import App from './App';
import { store } from './app/store';
import awsexports from './aws-exports';
import { CreateAccount } from './pages/createAccount';
import { CreateAccountVerify } from './pages/createAccount/verify';
import { SignIn } from './pages/signIn';
import * as serviceWorker from './serviceWorker';
import WebWeightsLogo from './svg/WebWeights-Logo.svg';


const persistor = persistStore(store);

Amplify.configure(awsconfig);
Amplify.configure(awsexports);

function PrivateRoute() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const checkAuthenticated = async () => {
    const response = await Amplify.Auth.currentAuthenticatedUser()
      .then(() => {
        return true;
      })
      .catch(() => {
        return false;
      });
    
    setIsAuthenticated(response);
  }

  useEffect(() => {
    checkAuthenticated();
  }, []);

  if(isAuthenticated){
    return <App/>;
  }else{
    return (
      <div
        className="access-container">
        <div
          className="background">
          <img alt="Logo" src={WebWeightsLogo} />
        </div>
        <div
          className="header">
          <img alt="Logo" src="/WebWeights-Logo.png"/>
        </div>
        <Router>
          <Route
            exact path="/"
            render={() => (
              <SignIn
                className="page"
                setIsAuthenticated={setIsAuthenticated}/>
            )}/>
          <Route
            exact path="/SignIn"
            render={() => (
              <SignIn
                className="page"
                setIsAuthenticated={setIsAuthenticated}/>
            )}/>
          <Route
            exact
            path="/CreateAccount"
            render={() => (
              <CreateAccount
                className="page"/>
            )}/>
          <Route
            exact
            path="/CreateAccount/Verify"
            render={() => (
              <CreateAccountVerify
                className="page"/>
            )}/>
        </Router>

        {/* <div
          className="background semi-circle"/> */}
        
      </div>
    );
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider>
          <PrivateRoute />
        </ChakraProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();