interface BackgroundShapeProps {
  className?: string;
  shapeColor?: string;
}

export const BackgroundShape: React.FC<BackgroundShapeProps> = ({
  className,
  shapeColor = "#4BBA88"
}) => {
  return (
    <svg
      className={className}
      viewBox="0 0 200 100"
    >
      <rect
        width={200}
        height={200}
        x={-70}
        y={-100}
        rx={2}
        ry={2}
        fill={shapeColor}
        transform="rotate(45)"
      />
    </svg>
  )
}