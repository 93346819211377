/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCompletedWorkout = /* GraphQL */ `
  query GetCompletedWorkout($id: ID!) {
    getCompletedWorkout(id: $id) {
      id
      username
      date
      sets {
        id
        username
        workoutId
        exerciseId
        exercise {
          id
          username
          name
          group
          sets {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
          bestSet {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
          lastSet {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
        }
        exerciseNumber
        setNumber
        weight
        reps
        date
      }
    }
  }
`;
export const getCompletedWorkouts = /* GraphQL */ `
  query GetCompletedWorkouts {
    getCompletedWorkouts {
      id
      username
      date
      sets {
        id
        username
        workoutId
        exerciseId
        exercise {
          id
          username
          name
          group
          sets {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
          bestSet {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
          lastSet {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
        }
        exerciseNumber
        setNumber
        weight
        reps
        date
      }
    }
  }
`;
export const getCompletedWorkoutsPagination = /* GraphQL */ `
  query GetCompletedWorkoutsPagination($limit: Int, $nextToken: String) {
    getCompletedWorkoutsPagination(limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        date
        sets {
          id
          username
          workoutId
          exerciseId
          exercise {
            id
            username
            name
            group
          }
          exerciseNumber
          setNumber
          weight
          reps
          date
        }
      }
      nextToken
    }
  }
`;
export const getExercises = /* GraphQL */ `
  query GetExercises {
    getExercises {
      id
      username
      name
      group
      sets {
        id
        username
        workoutId
        exerciseId
        exercise {
          id
          username
          name
          group
          sets {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
          bestSet {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
          lastSet {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
        }
        exerciseNumber
        setNumber
        weight
        reps
        date
      }
      bestSet {
        id
        username
        workoutId
        exerciseId
        exercise {
          id
          username
          name
          group
          sets {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
          bestSet {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
          lastSet {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
        }
        exerciseNumber
        setNumber
        weight
        reps
        date
      }
      lastSet {
        id
        username
        workoutId
        exerciseId
        exercise {
          id
          username
          name
          group
          sets {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
          bestSet {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
          lastSet {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
        }
        exerciseNumber
        setNumber
        weight
        reps
        date
      }
    }
  }
`;
export const getTargets = /* GraphQL */ `
  query GetTargets {
    getTargets {
      id
      exerciseId
      exercise {
        id
        username
        name
        group
        sets {
          id
          username
          workoutId
          exerciseId
          exercise {
            id
            username
            name
            group
          }
          exerciseNumber
          setNumber
          weight
          reps
          date
        }
        bestSet {
          id
          username
          workoutId
          exerciseId
          exercise {
            id
            username
            name
            group
          }
          exerciseNumber
          setNumber
          weight
          reps
          date
        }
        lastSet {
          id
          username
          workoutId
          exerciseId
          exercise {
            id
            username
            name
            group
          }
          exerciseNumber
          setNumber
          weight
          reps
          date
        }
      }
      weight
      reps
      date
    }
  }
`;
export const getSetsSince = /* GraphQL */ `
  query GetSetsSince($date: String) {
    getSetsSince(date: $date) {
      id
      username
      workoutId
      exerciseId
      exercise {
        id
        username
        name
        group
        sets {
          id
          username
          workoutId
          exerciseId
          exercise {
            id
            username
            name
            group
          }
          exerciseNumber
          setNumber
          weight
          reps
          date
        }
        bestSet {
          id
          username
          workoutId
          exerciseId
          exercise {
            id
            username
            name
            group
          }
          exerciseNumber
          setNumber
          weight
          reps
          date
        }
        lastSet {
          id
          username
          workoutId
          exerciseId
          exercise {
            id
            username
            name
            group
          }
          exerciseNumber
          setNumber
          weight
          reps
          date
        }
      }
      exerciseNumber
      setNumber
      weight
      reps
      date
    }
  }
`;
