import './styles.scss';

import { CheckIcon } from '@chakra-ui/icons';
import { Center, HStack, VStack } from '@chakra-ui/layout';
import { Button, IconButton, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { CgMoreO as MoreIcon } from "react-icons/cg";
import { 
  IoAddCircleOutline as AddIcon,
  IoCreateOutline as CreateIcon
} from "react-icons/io5";

import { BatchCreateSetsMutation, BatchCreateSetsMutationVariables, CreateSetInput, CreateSetMutation, CreateSetMutationVariables, CreateWorkoutMutation } from '../../API';
import { useAppDispatch,useAppSelector } from '../../app/hooks';
import { AppBar } from '../../components/appbar';
import { CreateExercise } from '../../components/createExercise';
import { NavBar } from '../../components/navbar';
import { batchCreateSets, createSet, createWorkout } from '../../graphql/mutations';
import callGraphQL from '../../helpers/GraphQL';
import { GetExercisesQueryV2, getExercisesV2 as getExercisesQueryV2, mapGetExercisesV2 } from '../../models/exercises';
import { ExerciseGroup } from '../../types';
import { loadExercises } from '../exercise/exercisesSlice';
import { ExerciseSetView } from './exerciseSetView';
import { ExerciseView } from './exerciseView';
import {
  addExercise,
  removeExercise,
  resetWorkout,
  selectWorkout,
} from './workoutSlice';

export const Workout: React.FC = () => {
  const workout = useAppSelector(selectWorkout);
  const dispatch = useAppDispatch();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showExerciseSetViewIndex, setShowExerciseSetViewIndex] = useState(0);

  useEffect(() => {
    getExercises();
    // eslint-disable-next-line 
  }, []);

  const getExercises = async () => {
    const exercises = mapGetExercisesV2(await callGraphQL<GetExercisesQueryV2>(getExercisesQueryV2));
    const groupedExercises: ExerciseGroup[] = [];
    exercises.forEach((exercise) => {
      const index = groupedExercises.findIndex((groupedExercise) => groupedExercise.group === exercise.group)
      if(index >= 0){
        groupedExercises[index].exercises?.push(exercise);
      } else {
        groupedExercises.push({
          group: exercise.group,
          exercises: [exercise]
        })
      }
    });
    dispatch(loadExercises(groupedExercises));
  }

  const handleAddExercise = () => {
    dispatch(addExercise());
    setShowExerciseSetViewIndex(workout.length + 1);
  };

  const handleSubmitWorkout = async () => {
    let workoutId: string | undefined;
    setIsLoading(true);

    await callGraphQL<CreateWorkoutMutation>(createWorkout)
      .then((res) => {
        console.log("🚀 ~ file: index.tsx ~ line 88 ~ .then ~ res", res);
        workoutId = res.data?.createWorkout?.id;
      },
      (error) => {
        console.log(error);
        console.log("Error creating workout");
        setIsLoading(false);
        toast({
          title: "Error, we were unable to create your workout",
          status: "error",
          description: `Error: ${error}`,
          isClosable: true
        });
      }
    );

    if (workoutId) {
      const createSetInputs: CreateSetInput[] = [];
      const errorArray: any[] = [];
      
      workout.forEach((exercise, exerciseIndex) => {
        const exerciseId = exercise.exercise?.id || '0';
        exercise.sets.forEach(async (set, setIndex) => {
          const createSetInput = {
            workoutId: workoutId,
            exerciseId: exerciseId,
            exerciseNumber: exerciseIndex,
            setNumber: setIndex,
            weight: set.weight,
            reps: set.reps,
          };
          await callGraphQL<CreateSetMutation>(createSet,
            createSetInput as CreateSetMutationVariables)
            .then(() => {
              createSetInputs.push(createSetInput);
            },
            (error) => {
              console.log(error);
              console.log(error.errors);
              console.log(error.errors[0].message);
              console.log("Error submitting sets");
              errorArray.push(error);
            }
          );
        })
      });

      if (errorArray.length > 0) {
        toast({
          title: "Error, we were unable to save your sets",
          status: "error",
          description: `Error: ${errorArray[0]}`,
          isClosable: true
        });
      } else {
        dispatch(resetWorkout());
        toast({
          title: "Success, your Workout was saved",
          status: "success",
          description: "Check out all your workouts to see your progress.",
          isClosable: true
        });
      }
    }
    setIsLoading(false);
  };

  const handleShowExerciseSetView = (x: number) => {
    setShowExerciseSetViewIndex(x + 1);
  }

  const handleDeleteExercise = (x: number) => {
    dispatch(removeExercise(x));
  }

  if (showExerciseSetViewIndex) {
    return (
      <ExerciseSetView
        workout={workout}
        close={() => handleShowExerciseSetView(-1)}
        initialIndex={showExerciseSetViewIndex}/>
    )
  }

  return (
    <>
      <NavBar
        pageTitle="New Workout">
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Exercise Options"
            icon={<MoreIcon/>}
            variant="ghost"
            className="workout-menu"/>
          <MenuList
            zIndex={9999}>
            <MenuGroup
              textAlign='left'
              title='Exercise'>
              <MenuItem
                onClick={handleAddExercise}
                icon={<AddIcon/>}>
                Add
              </MenuItem>
              <MenuItem
                onClick={() => setIsOpen(true)}
                icon={<CreateIcon/>}>
                Create
              </MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuGroup
              textAlign='left'
              title='Workout'>
              <MenuItem
                onClick={handleSubmitWorkout}
                icon={<CheckIcon/>}>
                Submit
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      </NavBar>
      <VStack
        margin="auto"
        padding={30}
        justify="center"
        width="100vw"
        maxW="550px"
        marginBottom="100px">
        <VStack
          w='100%'
          spacing='5'>
          {
            workout.length > 0 &&
            workout.map((exercise, index) => (
              <ExerciseView
                key={exercise.exercise?.id}
                onClick={() => handleShowExerciseSetView(index)}
                onDelete={() => handleDeleteExercise(index)}
                exercise={exercise} />
            ))
          }
          <HStack
            w='100%'
            className='workout-controls'
            justifyContent='space-between'
            align='center'
            spacing={5}>
            <Center
              className='button'
              as={Button}
              onClick={handleAddExercise}>
              <AddIcon
                className='icon'/>
              <span>
                Exercise
              </span>
            </Center>
            <Center
              className='button'
              as={Button}
              onClick={handleSubmitWorkout}
              isLoading={isLoading}>
              <CheckIcon
                className='icon'/>
              <span>
                Workout
              </span>
            </Center>
          </HStack>
        </VStack>
        <CreateExercise
          isOpen={isOpen}
          setIsOpen={setIsOpen}/>
      </VStack>
      <AppBar/>
    </>
  );
}
