import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, HStack, Input, Select, VStack } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { CreateExerciseMutation, CreateExerciseMutationVariables } from '../../API';
import { useAppDispatch } from '../../app/hooks';
import { addExercise } from '../../features/exercise/exercisesSlice';
import { createExercise } from '../../graphql/mutations';
import callGraphQL from '../../helpers/GraphQL';
import { mapCreateExercise } from '../../models/exercises';

interface CreateExerciseProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}
export const CreateExercise: React.FC<CreateExerciseProps> = ({isOpen, setIsOpen}) => {
  const dispatch = useAppDispatch();
  const [, setIdToken] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [exerciseGroup, setExerciseGroup] = useState<string>();
  const [exerciseName, setExerciseName] = useState<string>();
  const exerciseGroups = [
    {
      name: "Abs",
      value: "Abs"
    },
    {
      name: "Arms",
      value: "Arms"
    },
    {
      name: "Back",
      value: "Back"
    },
    {
      name: "Chest",
      value: "Chest"
    },
    {
      name: "Legs",
      value: "Legs"
    },
    {
      name: "Shoulders",
      value: "Shoulders"
    }
  ];

  useEffect(() => {
    getSessionToken();
  })

  const getSessionToken = async () => {
    const session = await Auth.currentSession();
    setIdToken(session.getIdToken().getJwtToken());
  }

  const handleOnClose = () => {
    setIsOpen(false);
  }

  const handleCreateExercise = async () => {
    if(exerciseName && exerciseGroup){
      setIsLoading(true);
      await callGraphQL<CreateExerciseMutation>(createExercise,
        {
          name: exerciseName,
          group: exerciseGroup
        } as CreateExerciseMutationVariables)
        .then((result) => {
          dispatch(addExercise(mapCreateExercise(result)));
          handleOnClose();
        },
        (error) => {
          setIsLoading(false);
          console.log(error);
        }
      );
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      placement="bottom"
      onClose={handleOnClose}
      isOpen={isOpen}>
      <DrawerOverlay/>
      <DrawerContent
        borderRadius="10px 10px 0px 0px"
        maxWidth="550px !important"
        margin="auto">
        <DrawerCloseButton/>
        <DrawerHeader>
          Create Exercise
        </DrawerHeader>
        <DrawerBody>
          <VStack
            paddingBottom="20px"
            spacing={4}>
            <Select
              placeholder="Select an exercise group"
              isFullWidth
              whiteSpace="nowrap"
              onChange={(event) => setExerciseGroup(event.currentTarget.value)}>
              {
                exerciseGroups.map((exerciseGroup, index) => <option key={index} value={exerciseGroup.name}>{exerciseGroup.name}</option>)
              }
            </Select>
            <Input
              placeholder="Exercise name"
              onChange={(event) => setExerciseName(event.currentTarget.value)}/>
            <HStack
              width="100%"
              justify="space-between">
              <Button
                onClick={handleOnClose}
                variant="ghost"
                disabled={isLoading}>
                Cancel
              </Button>
              <Button
                bgColor="#4bba88"
                color="white"
                isLoading={isLoading}
                onClick={handleCreateExercise}>
                Create Exercise
              </Button>
            </HStack>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
