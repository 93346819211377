import './styles.scss';

import { Skeleton, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { GetCompletedWorkoutsPaginationQuery } from '../../../API';
import { CompletedWorkoutCard } from '../../../components/completedWorkoutCardv2';
import { getCompletedWorkoutsPagination } from '../../../graphql/queries';
import callGraphQL from '../../../helpers/GraphQL';
import { mapGetWorkoutsPagination } from '../../../models/workout';
import { CompletedWorkout } from '../../../types';


const MostRecentWorkoutsSkeleton: React.FC = () => (
  <>
    <Skeleton
      w="100%"
      height="174px"
      borderRadius="8px"/>
    <Skeleton
      w="100%"
      height="174px"
      borderRadius="8px"/>
  </>
)

export const MostRecentWorkouts: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [completedWorkouts, setCompletedWorkouts] = useState<CompletedWorkout[]>();

  useEffect(() => {
    getCompletedWorkouts();
  }, []);
  
  const getCompletedWorkouts = async () => {
    setIsLoading(true);
    const workouts = mapGetWorkoutsPagination(await callGraphQL<GetCompletedWorkoutsPaginationQuery>(getCompletedWorkoutsPagination));
    setCompletedWorkouts(workouts.slice(0, 3));
    setIsLoading(false);
  }

  return (
    <div
      className='container recent-workouts'>
      <h2 className='header'>Most Recent Workouts</h2>
      <div
        className='content'>
        <VStack
          spacing={4}
          margin="auto"
          justify="center"
          width="100%"
          maxW="550px"
          flexDirection="column">
          {isLoading ?
            <MostRecentWorkoutsSkeleton/>
            :
            completedWorkouts?.map((completedWorkout, index) => (
              <CompletedWorkoutCard
                key={index}
                workout={completedWorkout}/>
            ))
          }
        </VStack>
      </div>
    </div>
  );
}
