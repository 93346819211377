import { GraphQLResult } from "@aws-amplify/api";

import { BatchCreateSetsMutation, CreateSetMutation, GetSetsSinceQuery } from "../../API";
import { Set} from "../../types";

const mapCreateSetMutation = (createSetMutation: GraphQLResult<CreateSetMutation>): Set => {
  return createSetMutation.data?.createSet as Set;
}

const mapBatchCreateSetsMutation = (batchCreateSetsMutation: GraphQLResult<BatchCreateSetsMutation>): Set[] => {
  return batchCreateSetsMutation.data?.batchCreateSets?.map(set => ({
    id: set?.id,
		workoutId: set?.workoutId,
		exerciseId: set?.exerciseId,
		exerciseNumber: set?.exerciseNumber,
		setNumber: set?.setNumber,
		weight: set?.weight,
		reps: set?.reps
  } as Set)) || [];
}

const mapGetSetsQuery = (mapGetSetsQuery: GraphQLResult<GetSetsSinceQuery>): Set[] => {
  return mapGetSetsQuery.data?.getSetsSince?.map(set => ({
    id: set?.id,
		workoutId: set?.workoutId,
		exerciseId: set?.exerciseId,
		exercise: set?.exercise,
		exerciseNumber: set?.exerciseNumber,
		setNumber: set?.setNumber,
		weight: set?.weight,
		reps: set?.reps
  } as Set)) || [];
}

export { mapCreateSetMutation as mapCreateSet }
export { mapBatchCreateSetsMutation as mapBatchCreateSets }
export { mapGetSetsQuery as mapGetSets }