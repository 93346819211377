import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { Exercise } from '../exercise';

export interface Set {
  id: number;
  weight: number | null;
  reps: number | null;
}

export interface ExerciseName {
  id: string | undefined;
  name: string | undefined;
  group: string | undefined;
  bestSet: Set | undefined;
  lastSet: Set | undefined;
}

export interface Exercise {
  exercise: ExerciseName | undefined;
  sets: Set[];
}

export interface WorkoutState {
  exercises: Exercise[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: WorkoutState = {
  exercises: [
    {
      exercise: undefined,
      sets: [
        {
          id: 0,
          weight: null,
          reps: null,
        }
      ]
    }
  ],
  status: 'idle'
};

export const workoutSlice = createSlice({
  name: 'workout',
  initialState,
  reducers: {
    resetWorkout: (state) => {
      state.exercises = [...initialState.exercises];
    },
    addExercise: (state) => {
      state.exercises.push({
        exercise: undefined,
        sets: [
          {
            id: 0,
            weight: null,
            reps: null,
          }
        ]
      });
    },
    removeExercise: (state, action) => {
      const exercises = [...state.exercises];
      state.exercises = exercises.filter((value, index) => index !== action.payload);
    },
    addSet: (state, action) => {
      const sets = [...state.exercises[action.payload].sets];
      sets.push({
        id: sets.length,
        weight: 0,
        reps: 0,
      })
      state.exercises[action.payload].sets = sets;
    },
    removeSet: (state, action) => {
      const {exerciseIndex, setIndex} = action.payload;
      const sets = [...state.exercises[exerciseIndex].sets.filter((value, index) => index !== setIndex)];
      state.exercises[exerciseIndex].sets = sets;
    },
    changeExercise: (state, action) => {
      const {exerciseIndex, exercise} = action.payload;
      const exercises = [...state.exercises];
      exercises[exerciseIndex].exercise = exercise;
      state.exercises = exercises;
    },
    changeSetWeight: (state, action) => {
      const {exerciseIndex, setIndex, weight} = action.payload;
      const set = state.exercises[exerciseIndex].sets[setIndex];
      set.weight = weight;
      state.exercises[exerciseIndex].sets[setIndex] = set;
    },
    changeSetReps: (state, action) => {
      const {exerciseIndex, setIndex, reps} = action.payload;
      const set = state.exercises[exerciseIndex].sets[setIndex];
      set.reps = reps;
      state.exercises[exerciseIndex].sets[setIndex] = set;
    }
  },
});

export const { resetWorkout, addExercise, removeExercise, addSet, removeSet, changeExercise, changeSetWeight, changeSetReps } = workoutSlice.actions;

export const selectWorkout = (state: RootState): Exercise[] => state.workout.exercises;

export default workoutSlice.reducer;
