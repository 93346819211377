import './styles.scss';

import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text} from '@chakra-ui/react';
import { useState } from 'react';
import { IoRemoveCircleOutline as RemoveIcon } from "react-icons/io5";

import { formatDate } from '../../helpers/DateFormatter';
import { CompletedWorkout } from '../../types';
import { BackgroundShape } from '../BackgroundShape';

interface CompletedWorkoutCardProps {
  className?: string;
  workout: CompletedWorkout;
  deleteWorkout?: (workoutID: string) => Promise<void>;
}

export const CompletedWorkoutCard: React.FC<CompletedWorkoutCardProps> = ({className, workout, deleteWorkout}) => {
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDeleteWorkout = async () => {
    if(deleteWorkout){
      setIsLoading(true);
      await deleteWorkout(workout.id);
      setIsLoading(false);
      onClose();
    }
  }

  const onClose = () => {
    setOpenModal(false);
  }

  const ConfirmModal = () => {
    return (
      <Drawer
        placement="bottom"
        onClose={onClose}
        isOpen={openModal}>
        <DrawerOverlay/>
        <DrawerContent
          borderRadius="10px 10px 0px 0px"
          maxWidth="550px !important"
          margin="auto">
          <DrawerCloseButton/>
          <DrawerHeader>
            Delete Workout
          </DrawerHeader>
          <DrawerBody>
            <Text
              fontSize="md">
              Are you sure you want to delete this workout?
            </Text>
            <Text
              fontSize="md">
              Completed on {formatDate(workout.date)}
            </Text>
            <Text
              fontSize="md">
              Progress will be lost, affecting different statistics.
            </Text>
          </DrawerBody>
          <DrawerFooter
            display="flex"
            justifyContent="space-between">
            <Button
              variant="ghost"
              onClick={onClose}
              isLoading={isLoading}>
              Cancel
            </Button>
            {/* {
              deleteWorkout && */}
                <Button
                  colorScheme="red"
                  mr={3}
                  onClick={handleDeleteWorkout}
                  leftIcon={<RemoveIcon/>}
                  isLoading={isLoading}>
                  Delete
                </Button>
            {/* } */}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  }

  const getWorkoutExerciseGroups = (): string[] => {
    // TODO: this needs ordering based on amount of exercises in each group
    const exerciseGroups = workout.summaryStats.exerciseGroups;
    if (exerciseGroups.length > 3) {
      return [
        ...exerciseGroups.slice(0, 2),
        `+${exerciseGroups.length - 2}`
      ]

    }
    return exerciseGroups.slice(0, 3);
  }

  return (
    <div
      className='completed-workout-card'>
      <div className='background-shape'/>
      <a
        className='date'  
        href={`/CompletedWorkouts/${workout.id}`}>
        {formatDate(workout.date)}
      </a>
      <div
        className='additional-info'>
        <div
          className='exercise-group-names'>
          {
            getWorkoutExerciseGroups().map((exerciseGroup, index) => (
              <span key={index}>{exerciseGroup}</span>
            ))
          }
        </div>
        <div
          className='stat-group'>
          <div
            className='stat'>
            <span className='title'>Exercises</span>
            <span className='value'>{workout.summaryStats.numberExercises}</span>
          </div>
          <div
            className='stat'>
            <span className='title'>Sets Avg.</span>
            <span className='value'>{workout.summaryStats.avgSets}</span>
          </div>
          <div
            className='stat'>
            <span className='title'>Reps Avg.</span>
            <span className='value'>{workout.summaryStats.avgReps}</span>
          </div>
        </div>
      </div>
      <ConfirmModal />
      <BackgroundShape
        className='background-shape'
        shapeColor="#0099FF"
      />
    </div>
  );
}
