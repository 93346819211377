import './styles.scss';

import { Heading } from '@chakra-ui/react';
import React from 'react';

interface NavBarProps {
  pageTitle: string;
}

export const NavBar: React.FC<NavBarProps> = ({pageTitle, children}) => {
  return (
    <header
      className='navbar'>
      <Heading
        margin={0}
        marginInlineStart={0}
        size="xl"
        isTruncated
        className='title'>
        {pageTitle}
      </Heading>
      {children}
    </header>
  );
}
