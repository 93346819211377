import { VStack } from '@chakra-ui/layout';
import { Skeleton, useToast } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';

import { GetCompletedWorkoutsPaginationQuery } from '../../API';
import { AppBar } from '../../components/appbar';
import { CompletedWorkoutCard } from '../../components/completedWorkoutCardv2';
import { NavBar } from '../../components/navbar';
import { getCompletedWorkoutsPagination } from '../../graphql/queries';
import callGraphQL from '../../helpers/GraphQL';
import { mapGetWorkoutsPagination } from '../../models/workout';
import { CompletedWorkout } from '../../types';

export const CompletedWorkouts: React.FC = () => {  
  const toast = useToast();
  const [idToken, setIdToken] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [completedWorkouts, setCompletedWorkouts] = useState<CompletedWorkout[]>();
  
  useEffect(() => {
    getSessionToken();
    getCompletedWorkouts();
  }, [idToken]);

  const getSessionToken = async () => {
    const session = await Auth.currentSession();
    setIdToken(session.getIdToken().getJwtToken());
  }

  const getCompletedWorkouts = async () => {
    setIsLoading(true);
    const workouts = mapGetWorkoutsPagination(await callGraphQL<GetCompletedWorkoutsPaginationQuery>(getCompletedWorkoutsPagination));
    setCompletedWorkouts(workouts);
    setIsLoading(false);
  }

  const deleteWorkout = async (workoutID: string) => {
    if(idToken){
      const requestHeaders: HeadersInit = new Headers();
      requestHeaders.set('Content-Type', 'application/json');
      requestHeaders.set('Authorization', idToken);
      
      await fetch(
        "https://swfgeedjo0.execute-api.eu-west-2.amazonaws.com/Test/workout?id=" + encodeURIComponent(workoutID),
        {
          method: "DELETE",
          headers: requestHeaders
        }
        )
        .then(res => res.json())
        .then((result) => {
          setCompletedWorkouts(result.Workouts);
          toast({
            title: "Success, your workout was deleted",
            status: "success",
            isClosable: true
          });
        },
        (error) => {
          console.log("Error deleteing workout", error);
          toast({
            title: "Error, we were unable to delete your workout",
            status: "error",
            description: `Error: ${error}`,
            isClosable: true
          });
        }
      );
    }
  }

  return (
    <>
      <NavBar
        pageTitle="Completed Workouts"/>
        <VStack
          spacing={4}
          margin="auto"
          justify="center"
          width="100vw"
          padding="0 30px"
          paddingTop="40px"
          maxW="550px"
          flexDirection="column">
          {isLoading ?
            <>
              <Skeleton
                margin="0 10px"
                w="100%"
                height="174px"
                borderRadius="8px"/>
              <Skeleton
                margin="0 10px"
                w="100%"
                height="174px"
                borderRadius="8px"/>
            </>
            :
            completedWorkouts?.map((completedWorkout, index) => (
              <CompletedWorkoutCard
                key={index}
                workout={completedWorkout}
                deleteWorkout={deleteWorkout}/>
            ))
          }
        </VStack>
      <AppBar/>
    </>
  );
}
