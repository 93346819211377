import './styles.scss';

import { Button, Center, Divider, Heading, HStack, Input, useToast, VStack } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

interface CreateAccountVerifyProps {
  className?: string;
}

export const CreateAccountVerify: React.FC<CreateAccountVerifyProps> = ({className}) => {
  const history = useHistory();
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  const handleSignInClick = () => {
    history.push('/SignIn');
  }

  const handleCreateAccountSubmit = async () => {
    try {
      await Auth.confirmSignUp(
        email.replace(/[@.]/g, '|'),
        verificationCode
      );
      history.push("/SignIn");
    } catch (error) {
      toast({
        title: "Error, we were unable to sign you in",
        status: "error",
        description: error.message,
        isClosable: true
      });
    }
  };

  const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if(event.key === 'Enter'){
      handleCreateAccountSubmit();
    }
  }
  
  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setEmail(event.currentTarget.value);
  };

  const handleVerificationCodeChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setVerificationCode(event.currentTarget.value);
  };

  return (
    <div
      className={`verify-account-container ${className}`}>
      <VStack
        w="100%"
        spacing="10px">
        <Input
          id="email"
          label="Email"
          placeholder="Enter email"
          type="text"
          value={email}
          onChange={handleEmailChange}
          onKeyPress={handleKeyPress}
          bgColor="#edf2f7"/>
        <Input
          id="verificationCode"
          label="Verification Code"
          placeholder="Enter verifcation code"
          type="text"
          value={verificationCode}
          onChange={handleVerificationCodeChange}
          onKeyPress={handleKeyPress}
          bgColor="#edf2f7"/>
        <Button
          w="100%"
          bgColor="#2D3748"
          color="white"
          onClick={handleCreateAccountSubmit}>
          Verify Account
        </Button>
      </VStack>

      <HStack
        className='divider'>
        <Divider />
        <span>OR</span>
        <Divider />
      </HStack>
      
      <Button
        w="100%"
        variant="ghost"
        onClick={handleSignInClick}>
        Already have an Account? Sign In
      </Button>
    </div>
  );
}
