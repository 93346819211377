import './styles.scss';

import { Button, Center, Divider, Heading, HStack, Input, useToast, VStack } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

interface SignInProps {
  className?: string;
  setIsAuthenticated:  React.Dispatch<React.SetStateAction<boolean>>;
}

export const SignIn: React.FC<SignInProps> = ({className, setIsAuthenticated}) => {
  const history = useHistory();
  const toast = useToast();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleCreateAccountClick = () => {
    history.push('/CreateAccount');
  }

  const handleSignInSubmit = async () => {
    try {
      const userObject = await Auth.signIn(
        email.replace(/[@.]/g, '|'),
        password
      );

      if (userObject.challengeName){
        //User has not been verified, send to verification page
        history.push("/CreateAccount/Verify");
      } else {
        setIsAuthenticated(true);
        history.push("");
      }
    } catch (error) {
      toast({
        title: "Error, we were unable to sign you in",
        status: "error",
        description: error as string,
        isClosable: true
      });
    }
  }

  const handleKeyPress: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    if(event.key === 'Enter'){
      handleSignInSubmit();
    }
  }
  
  const handleEmailChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setEmail(event.currentTarget.value);
  };

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setPassword(event.currentTarget.value);
  };

  return (
    <div
      className={`sign-in-container ${className}`}>
      <VStack
        w="100%"
        spacing="10px">
        <Input
          id="email"
          label="Email"
          placeholder="Enter email"
          type="text"
          value={email}
          onChange={handleEmailChange}
          onKeyPress={handleKeyPress}
          bgColor="#edf2f7"/>
        <Input
          id="password"
          label="Password"
          placeholder="Enter password"
          type="password"
          value={password}
          onChange={handlePasswordChange}
          onKeyPress={handleKeyPress}
          bgColor="#edf2f7"/>
        <Button
          w="100%"
          bgColor="#2D3748"
          color="white"
          onClick={handleSignInSubmit}>
          Sign In
        </Button>
      </VStack>

      <HStack
        className='divider'>
        <Divider />
        <span>OR</span>
        <Divider />
      </HStack>
      
      <Button
        w="100%"
        variant="ghost"
        onClick={handleCreateAccountClick}
        className="button create-account">
        Create Account
      </Button>
    </div>
  );
}
