import { HStack, VStack } from '@chakra-ui/layout';
import { NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from '@chakra-ui/number-input';
import { CloseButton } from '@chakra-ui/react';
import React, { FocusEventHandler } from 'react';

import { useAppDispatch } from '../../app/hooks';
import {
  changeSetReps,
  changeSetWeight,
  removeSet,
  Set as SetType,
} from '../workout/workoutSlice';

interface SetProps {
  index: number;
  exerciseIndex: number;
  set: SetType;
  disabled?: boolean;
}

export const Set: React.FC<SetProps> = ({ index, exerciseIndex, set, disabled = false }) => {
  const dispatch = useAppDispatch();

  const handleWeightChange: FocusEventHandler<HTMLInputElement> = (event) => {
    const value = event.currentTarget.value;
    dispatch(changeSetWeight({exerciseIndex: exerciseIndex, setIndex: index, weight: value}));
  }

  const handleRepsChange: FocusEventHandler<HTMLInputElement> = (event) => {
    const value = event.currentTarget.value;
    dispatch(changeSetReps({exerciseIndex: exerciseIndex, setIndex: index, reps: value}));
  }

  return (
    <HStack
      alignItems="center"
      justify="center">
      <VStack
        align="flex-start">
        <NumberInput defaultValue={set.weight ? set.weight : ""} min={0} onBlur={handleWeightChange} isDisabled={disabled}>
          <NumberInputField placeholder="0"/>
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </VStack>
      <VStack
        align="flex-start">
        <NumberInput defaultValue={set.reps ? set.reps : ""} step={1} min={0} onBlur={handleRepsChange} isDisabled={disabled}>
          <NumberInputField placeholder="0"/>
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </VStack>
      { !disabled &&
        <CloseButton
          onClick={() => dispatch(removeSet({exerciseIndex: exerciseIndex, setIndex: index}))}/>
      }
    </HStack>
  );
}
