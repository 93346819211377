import { GraphQLResult } from "@aws-amplify/api";

import { CreateExerciseMutation, ExerciseGroup, GetExercisesQuery } from "../../API";
import { Exercise } from "../../types";

export type GetExercisesQueryV2 = {
  getExercises:  Array< {
    __typename: "Exercise",
    id: string,
    username: string,
    name: string | null,
    group: ExerciseGroup | null,
    bestSet:  {
      __typename: "Set",
      id: string,
      username: string,
      workoutId: string,
      exerciseId: string,
      exerciseNumber: number,
      setNumber: number,
      weight: number | null,
      reps: number | null,
    } | null,
    lastSet:  {
      __typename: "Set",
      id: string,
      username: string,
      workoutId: string,
      exerciseId: string,
      exerciseNumber: number,
      setNumber: number,
      weight: number | null,
      reps: number | null,
    } | null,
  } | null > | null,
};

export const getExercisesV2 = /* GraphQL */ `
  query GetExercises {
    getExercises {
      id
      username
      name
      group
      bestSet {
        id
        username
        workoutId
        exerciseId
        exerciseNumber
        setNumber
        weight
        reps
      }
      lastSet {
        id
        username
        workoutId
        exerciseId
        exerciseNumber
        setNumber
        weight
        reps
      }
    }
  }
`;

const mapGetExercisesQueryV2 = (getExercisesQuery: GraphQLResult<GetExercisesQueryV2>): Exercise[] => {
  return getExercisesQuery.data?.getExercises?.map(exercise => ({
    id: exercise?.id,
    name: exercise?.name,
    group: exercise?.group,
    bestSet: exercise?.bestSet,
    lastSet: exercise?.lastSet
  } as Exercise)) || [];
}

const mapGetExercisesQuery = (getExercisesQuery: GraphQLResult<GetExercisesQuery>): Exercise[] => {
  return getExercisesQuery.data?.getExercises?.map(exercise => ({
    id: exercise?.id,
    name: exercise?.name,
    group: exercise?.group
  } as Exercise)) || [];
}

const mapCreateExerciseMutation = (createExerciseMutation: GraphQLResult<CreateExerciseMutation>): Exercise => {
  return createExerciseMutation.data?.createExercise as unknown as Exercise;
}

export { mapGetExercisesQuery as mapGetExercises }
export { mapGetExercisesQueryV2 as mapGetExercisesV2 }
export { mapCreateExerciseMutation as mapCreateExercise }