export const formatDate = (dateString: string): string => {
  const date = new Date(dateString.replace(' ', 'T'));
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: '2-digit',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };
  return date.toLocaleDateString(undefined, options);
}

export const getDateNum = (dateString: string): number => {
  const date = new Date(dateString.replace(' ', 'T'));
  return date.valueOf();
}