import { VStack } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { GetCompletedWorkoutQuery, GetCompletedWorkoutQueryVariables } from '../../../API';
import { NavBar } from '../../../components/navbar';
import { ExerciseSetView } from '../../../features/workout/exerciseSetView';
import { ExerciseView } from '../../../features/workout/exerciseView';
import { Exercise } from '../../../features/workout/workoutSlice';
import { getCompletedWorkout as getCompletedWorkoutGraphQL } from '../../../graphql/queries';
import { formatDate } from '../../../helpers/DateFormatter';
import callGraphQL from '../../../helpers/GraphQL';
import { mapGetWorkout } from '../../../models/workout';
import { CompletedWorkout } from '../../../types';

interface RouteParams {
  id: string;
}

interface WorkoutProps extends RouteComponentProps<RouteParams> {
  className?: string;
}

export const Workout: React.FC<WorkoutProps> = ({...props}) => {
  const { id } = props.match.params;
  const [idToken, setIdToken] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [workout, setWorkout] = useState<CompletedWorkout>();
  const [showExerciseSetViewIndex, setShowExerciseSetViewIndex] = useState(0);
  
  useEffect(() => {
    getSessionToken();
    getCompletedWorkout();
  }, [idToken]);

  const getSessionToken = async () => {
    const session = await Auth.currentSession();
    setIdToken(session.getIdToken().getJwtToken());
  }

  const getCompletedWorkout = async () => {
    await callGraphQL<GetCompletedWorkoutQuery>(getCompletedWorkoutGraphQL,
      {
        id: id
      } as GetCompletedWorkoutQueryVariables)
      .then((result) => {
        setWorkout(mapGetWorkout(result));
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
        console.log("Error retrieving workout");
        setIsLoading(false);
      }
    );
  }

  const handleShowExerciseSetView = (x: number) => {
    setShowExerciseSetViewIndex(x + 1);
  }

  if (showExerciseSetViewIndex && workout && workout.exercises) {
    return (
      <ExerciseSetView
        workout={workout.exercises as unknown as Exercise[]}
        close={() => handleShowExerciseSetView(-1)}
        initialIndex={showExerciseSetViewIndex}
        disabled/>
    )
  }

  return (
    <>
      <NavBar
        pageTitle={workout ? formatDate(workout.date) : "Workout"}/>
        {!isLoading && workout && workout.exercises ?
        <VStack
          spacing={5}
          margin="auto"
          padding={30}
          justify="center"
          width="100vw"
          paddingTop="40px"
          maxW="550px">
          {
            workout.exercises.length > 0 &&
            workout.exercises.map((exercise, index) => (
              <ExerciseView
                key={exercise.exercise?.id}
                onClick={() => handleShowExerciseSetView(index)}
                exercise={exercise as unknown as Exercise} />
            ))
          }
        </VStack>
        :
        <VStack
          spacing={5}
          margin="auto"
          padding={30}
          justify="center"
          width="100vw"
          paddingTop="40px"
          maxW="550px">
          <Skeleton
            w="100%"
            height="141px"
            rounded='lg'/>
          <Skeleton
            w="100%"
            height="141px"
            rounded='lg'/>
        </VStack>
      }
    </>
  );
}
