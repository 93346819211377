import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';
import { ExerciseGroup } from "../../types";

export interface ExercisesState {
  exerciseGroups: ExerciseGroup[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: ExercisesState = {
  exerciseGroups: [],
  status: 'idle'
};

export const exercisesSlice = createSlice({
  name: 'exercise',
  initialState,
  reducers: {
    loadExercises: (state, action) => {
      state.exerciseGroups = action.payload;
    },
    addExercise: (state, action) => {
      const index = state.exerciseGroups.findIndex((exerciseGroup) => exerciseGroup.group === action.payload.group);
      if (index >= 0) {
        state.exerciseGroups[index].exercises?.push(action.payload);

      } else {
        state.exerciseGroups.push({
          group: action.payload.group,
          exercises: [action.payload]
        })
      }
    }
  },
});

export const { addExercise, loadExercises } = exercisesSlice.actions;

export const selectExercises = (state: RootState): ExerciseGroup[] => state.exercises.exerciseGroups;

export default exercisesSlice.reducer;
