import { GraphQLResult } from "@aws-amplify/api";

import { CreateWorkoutMutation, GetCompletedWorkoutQuery, GetCompletedWorkoutsPaginationQuery, GetCompletedWorkoutsQuery } from "../../API";
import { CompletedWorkout, Set as WorkoutSet, SummaryStats, Workout, WorkoutExercise } from "../../types";

const mapGetWorkoutQuery = (mapGetWorkoutQuery: GraphQLResult<GetCompletedWorkoutQuery>): CompletedWorkout => {
	const workout = mapGetWorkoutQuery.data?.getCompletedWorkout as unknown as Workout;
	const sortedSets = workout.sets.sort((setA, setB) => setA.exerciseNumber === setB.exerciseNumber ? setA.setNumber - setB.setNumber : setA.exerciseNumber - setB.exerciseNumber);
	const workoutExercises: WorkoutExercise[] = [];
	sortedSets.forEach((set) => {
		if(workoutExercises[set.exerciseNumber]){
			workoutExercises[set.exerciseNumber].sets.push(set);
		}else{
			workoutExercises.push({
				exercise: set.exercise,
				sets: [set]
			});
		}
	});

  return {
    id: workout?.id,
    date: workout?.date,
    exercises: workoutExercises,
		summaryStats: getSummaryStats(workout.sets as WorkoutSet[])
	} as CompletedWorkout;
}

const mapGetWorkoutsQuery = (mapGetWorkoutsQuery: GraphQLResult<GetCompletedWorkoutsQuery>): CompletedWorkout[] => {
  return mapGetWorkoutsQuery.data?.getCompletedWorkouts?.map(workout => ({
    id: workout?.id,
    date: workout?.date,
    sets: workout?.sets,
		summaryStats: getSummaryStats(workout?.sets as unknown as WorkoutSet[])
  } as CompletedWorkout)) || [];
}

const mapGetWorkoutsPaginationQuery = (mapGetWorkoutsQuery: GraphQLResult<GetCompletedWorkoutsPaginationQuery>): CompletedWorkout[] => {
  const mappedWorkouts = mapGetWorkoutsQuery.data?.getCompletedWorkoutsPagination?.items?.map(workout => ({
    id: workout?.id,
    date: workout?.date,
		summaryStats: getSummaryStats(workout?.sets as WorkoutSet[])
  } as CompletedWorkout)) || [];

	return mappedWorkouts;
}

const mapCreateWorkoutMutation = (mapCreateWorkoutMutation: GraphQLResult<CreateWorkoutMutation>): Workout => {
	return mapCreateWorkoutMutation.data?.createWorkout as unknown as Workout;
}

const getSummaryStats = (sets: WorkoutSet[]): SummaryStats => {
	const exerciseGroups = new Set<string>();
	const totalSets = sets.length;
	let totalReps = 0;
	let totalWeight = 0;
	let totalExercises = 0;
	sets.forEach((set) => {
		const exerciseNumber = set.exerciseNumber + 1;
		totalReps += set.reps;
		totalWeight += set.weight;
		totalExercises = totalExercises < exerciseNumber ? exerciseNumber : totalExercises;
		exerciseGroups.add(set.exercise.group || '');
	});
	return {
		exerciseGroups: Array.from(exerciseGroups) as string[],
		numberExercises: totalExercises,
		avgSets: Math.round(totalSets/totalExercises),
		avgReps: Math.round(totalReps/sets.length),
		avgWeight: Math.round(totalWeight/sets.length)
	}
}

export { mapGetWorkoutQuery as mapGetWorkout }
export { mapGetWorkoutsQuery as mapGetWorkouts }
export { mapGetWorkoutsPaginationQuery as mapGetWorkoutsPagination }
export { mapCreateWorkoutMutation as mapCreateWorkout }