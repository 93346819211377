/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createExercise = /* GraphQL */ `
  mutation CreateExercise($name: String!, $group: ExerciseGroup!) {
    createExercise(name: $name, group: $group) {
      id
      username
      name
      group
      sets {
        id
        username
        workoutId
        exerciseId
        exercise {
          id
          username
          name
          group
          sets {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
          bestSet {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
          lastSet {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
        }
        exerciseNumber
        setNumber
        weight
        reps
        date
      }
      bestSet {
        id
        username
        workoutId
        exerciseId
        exercise {
          id
          username
          name
          group
          sets {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
          bestSet {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
          lastSet {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
        }
        exerciseNumber
        setNumber
        weight
        reps
        date
      }
      lastSet {
        id
        username
        workoutId
        exerciseId
        exercise {
          id
          username
          name
          group
          sets {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
          bestSet {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
          lastSet {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
        }
        exerciseNumber
        setNumber
        weight
        reps
        date
      }
    }
  }
`;
export const createWorkout = /* GraphQL */ `
  mutation CreateWorkout {
    createWorkout {
      id
      username
      date
      sets {
        id
        username
        workoutId
        exerciseId
        exercise {
          id
          username
          name
          group
          sets {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
          bestSet {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
          lastSet {
            id
            username
            workoutId
            exerciseId
            exerciseNumber
            setNumber
            weight
            reps
            date
          }
        }
        exerciseNumber
        setNumber
        weight
        reps
        date
      }
    }
  }
`;
export const createSet = /* GraphQL */ `
  mutation CreateSet(
    $workoutId: ID!
    $exerciseId: ID!
    $exerciseNumber: Int!
    $setNumber: Int!
    $weight: Float
    $reps: Int
  ) {
    createSet(
      workoutId: $workoutId
      exerciseId: $exerciseId
      exerciseNumber: $exerciseNumber
      setNumber: $setNumber
      weight: $weight
      reps: $reps
    ) {
      id
      username
      workoutId
      exerciseId
      exercise {
        id
        username
        name
        group
        sets {
          id
          username
          workoutId
          exerciseId
          exercise {
            id
            username
            name
            group
          }
          exerciseNumber
          setNumber
          weight
          reps
          date
        }
        bestSet {
          id
          username
          workoutId
          exerciseId
          exercise {
            id
            username
            name
            group
          }
          exerciseNumber
          setNumber
          weight
          reps
          date
        }
        lastSet {
          id
          username
          workoutId
          exerciseId
          exercise {
            id
            username
            name
            group
          }
          exerciseNumber
          setNumber
          weight
          reps
          date
        }
      }
      exerciseNumber
      setNumber
      weight
      reps
      date
    }
  }
`;
export const batchCreateSets = /* GraphQL */ `
  mutation BatchCreateSets($sets: [CreateSetInput]) {
    batchCreateSets(sets: $sets) {
      id
      username
      workoutId
      exerciseId
      exercise {
        id
        username
        name
        group
        sets {
          id
          username
          workoutId
          exerciseId
          exercise {
            id
            username
            name
            group
          }
          exerciseNumber
          setNumber
          weight
          reps
          date
        }
        bestSet {
          id
          username
          workoutId
          exerciseId
          exercise {
            id
            username
            name
            group
          }
          exerciseNumber
          setNumber
          weight
          reps
          date
        }
        lastSet {
          id
          username
          workoutId
          exerciseId
          exercise {
            id
            username
            name
            group
          }
          exerciseNumber
          setNumber
          weight
          reps
          date
        }
      }
      exerciseNumber
      setNumber
      weight
      reps
      date
    }
  }
`;
