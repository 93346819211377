import './styles.scss';

import { Avatar, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { IoAddCircleOutline as AddIcon } from "react-icons/io5";
import { useHistory } from 'react-router-dom';

import { GetSetsSinceQuery } from '../../API';
import { AppBar } from '../../components/appbar';
import { NavBar } from '../../components/navbar';
import { getSetsSince } from '../../graphql/queries';
import callGraphQL from '../../helpers/GraphQL';
import { Statistics as StatsType,statisticsData, TopExerciseGroup, topExerciseGroups } from '../../helpers/HomeStatistics';
import { mapGetSets } from '../../models/sets';
import { MostRecentWorkouts } from './MostRecentWorkouts';
import { Statistics } from './Statistics';
import { TopExerciseGroups } from './TopExerciseGroups';

export const Home: React.FC = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [exerciseGroupStats, setExerciseGroupStats] = useState<TopExerciseGroup[]>();
  const [statsData, setStatsData] = useState<StatsType>();

  const checkAuthenticated = async () => {
    await Auth.currentAuthenticatedUser();
  }

  useEffect(() => {
    checkAuthenticated();
    getLastDaysStats();
  }, []);
  
  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      history.push('/');
      window.location.reload();
    } catch (error) {
      console.log('error signing out:', error);
    }
  };
  
  const getLastDaysStats = async () => {
    setIsLoading(true);
    const date = new Date();
    date.setDate(date.getDate() - 7);
    await callGraphQL<GetSetsSinceQuery>(getSetsSince, {date: `${date.getFullYear()}-${(date.getMonth() + 1) < 10 && '0'}${date.getMonth() + 1}-${date.getDate() < 10 && '0'}${date.getDate()}`})
      .then((result) => {
        console.log("🚀 ~ file: index.tsx ~ line 51 ~ .then ~ result", result);
        const mappedData = mapGetSets(result);
        setExerciseGroupStats(topExerciseGroups(mappedData));
        setStatsData(statisticsData(mappedData));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        const mappedData = mapGetSets(error);
        setExerciseGroupStats(topExerciseGroups(mappedData));
        setStatsData(statisticsData(mappedData));
        setIsLoading(false);
      });
  }

  return (
    <>
      <NavBar
        pageTitle='Home'>
        <Menu>
          <Avatar
            as={MenuButton}
            aria-label="Profile Options"
            className="avatar" />
          <MenuList
            zIndex={9999}>
            <MenuItem
              onClick={handleSignOut}
              icon={<AddIcon/>}>
              Sign Out
            </MenuItem>
          </MenuList>
        </Menu>
      </NavBar>
      <TopExerciseGroups
        loading={isLoading}
        exerciseGroupStats={exerciseGroupStats}/>
      <Statistics
        loading={isLoading}
        data={statsData}/>
      <MostRecentWorkouts/>
      <AppBar/>
    </>
  );
}
