import './styles.scss';

import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import { Exercise } from '../../exercise';
import { Exercise as ExerciseType } from '../workoutSlice';

interface ExerciseSetViewProps {
  workout: ExerciseType[];
  initialIndex?: number;
  close: () => void;
  disabled?: boolean
}

export const ExerciseSetView: React.FC<ExerciseSetViewProps> = ({workout, initialIndex = 0, close, disabled = false}) => {
  const [index, ] = useState(initialIndex - 1);

  const handleChangeIndex = (index: number, indexLatest: number) => {
    console.log("index", index);
    console.log("indexLatest", indexLatest);
  }

  return (
    <div
      className='exercise-set-view'>
      <SwipeableViews
        enableMouseEvents
        index={index}
        onChangeIndex={handleChangeIndex}
        className='swipeable-view'>
        {
          workout.length > 0 &&
          workout.map((exercise, index) => (
            <Exercise
              key={exercise.exercise?.id}
              index={index}
              exercise={exercise}
              close={close}
              disabled={disabled}/>
          ))
        }
      </SwipeableViews>
    </div>
  );
}
