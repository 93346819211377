import './styles.scss';

import { IconButton } from '@chakra-ui/react';
import React from 'react';
import { BiDumbbell } from "react-icons/bi";
import { HiOutlineHome } from "react-icons/hi";
import { RiBarChart2Line } from "react-icons/ri";
import { Link as ReactLink } from 'react-router-dom';

export const AppBar: React.FC = () => {
  const pathname = window.location.pathname;

  return (
    <div className="appbar">
      <IconButton
        variant="ghost"
        icon={<HiOutlineHome/>}
        aria-label="Home"
        as={ReactLink}
        to="/"
        className={pathname === '/' ? 'selected' : ''}/>
      <IconButton
        variant="ghost"
        icon={<BiDumbbell/>}
        aria-label="Home Selected"
        as={ReactLink}
        to="/NewWorkout"
        className={pathname === '/NewWorkout' ? 'selected' : ''}/>
      <IconButton
        variant="ghost"
        icon={<RiBarChart2Line/>}
        aria-label="Stats"
        as={ReactLink}
        to="/CompletedWorkouts"
        className={pathname === '/CompletedWorkouts' ? 'selected' : ''}/>
    </div>
  );
}
