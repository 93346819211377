import './styles.scss';

import { Center, CloseButton, Heading, HStack,LinkBox, LinkOverlay, StatGroup } from '@chakra-ui/react';

import { StatCard } from '../../../components/statCard';
import { Exercise } from '../workoutSlice';

interface ExerciseViewProps {
  exercise: Exercise;
  onClick: () => void;
  onDelete?: () => void;
}

export const ExerciseView: React.FC<ExerciseViewProps> = ({ exercise, onClick, onDelete }) => {

  const handleClick = () => {
    onClick();
  }
  const handleDelete = () => {
    if (onDelete) {
      onDelete();
    }
  }

  const getTotalReps = (): number => {
    let totalReps = 0;
    exercise.sets.forEach((set) => {
      totalReps += +(set.reps || 0);
    })
    return totalReps;
  }

  const getTotalWeight = (): number => {
    let totalWeight = 0;
    exercise.sets.forEach((set) => {
      totalWeight += +(set.weight || 0);
    })
    return totalWeight;
  }

  return (
    <LinkBox
      rounded='lg'
      w='100%'
      p='5'
      className={`exercise ${exercise.exercise?.group?.toLowerCase()}`}>
      <Center
        className='group-name'>
        {exercise.exercise?.group}
      </Center>
      <HStack
        justifyContent='space-between'>
        <Heading
          size='md'
          p='10px'
          textAlign='left'
          className='exercise-name'>
          <LinkOverlay onClick={handleClick}>
            {exercise.exercise?.name || 'New Exercise'}
          </LinkOverlay>
        </Heading>
        {
          onDelete &&
            <CloseButton
              onClick={handleDelete}
              zIndex={1}/>
        }
      </HStack>
      <StatGroup>
        <StatCard
          label="Sets"
          stat={exercise.sets.length}/>
        <StatCard
          label="Reps"
          stat={getTotalReps()}/>
        <StatCard
          label="Weight (kg)"
          stat={getTotalWeight()}/>
      </StatGroup>
    </LinkBox>
  );
}
