import './styles.scss';

import { Skeleton, Stat, StatHelpText, StatLabel, StatNumber } from '@chakra-ui/react';
import React from 'react';

import { Statistics as StatsType } from '../../../helpers/HomeStatistics';

interface StatisticsProps {
  data: StatsType | undefined;
  loading: boolean;
}

const StatisticsSkeleton: React.FC = () => (
  <>
    <Skeleton
      w="100%"
      height="126px"
      borderRadius="8px"/>
    <Skeleton
      w="100%"
      height="126px"
      borderRadius="8px"/>
    <Skeleton
      w="100%"
      height="126px"
      borderRadius="8px"/>
    <Skeleton
      w="100%"
      height="126px"
      borderRadius="8px"/>
  </>
)

export const Statistics: React.FC<StatisticsProps> = ({data, loading}) => {  
  return (
    <div
      className='container stat'>
      <h2 className='header'>Statistics</h2>
      <h5 className='sub-header'>Last 7 Days</h5>
      <div
        className='content'>
        {
          (loading || !data) ? (
            <StatisticsSkeleton />
          ) : (
            <>
              <Stat>
                <StatLabel>Weight</StatLabel>
                <StatNumber>{data?.averageWeight.toFixed(1)}kg</StatNumber>
                <StatHelpText>
                  Avg. per Set
                </StatHelpText>
              </Stat>

              <Stat>
                <StatLabel>Workouts</StatLabel>
                <StatNumber>{data?.numberWorkouts}</StatNumber>
                <StatHelpText>
                  This Week 🥇
                </StatHelpText>
              </Stat>
              
              <Stat>
                <StatLabel>Reps</StatLabel>
                <StatNumber>
                  {data?.averageReps.toFixed()}
                </StatNumber>
                <StatHelpText>
                  Avg. per Set
                </StatHelpText>
              </Stat>
              
              <Stat>
                <StatLabel>Sets</StatLabel>
                <StatNumber>
                  {data?.averageSets.toFixed()}
                </StatNumber>
                <StatHelpText>
                  Avg. per Workout
                </StatHelpText>
              </Stat>
            </>
          )
        }
      </div>
    </div>
  );
}
