import { Stat, StatHelpText,StatLabel, StatNumber } from '@chakra-ui/react';

interface StatCardProps {
  className?: string;
  label?: string;
  stat: string | number;
  helperText?: string | number;
}

export const StatCard: React.FC<StatCardProps> = ({className, label, stat, helperText}) => {
  return (
    <Stat
      className={className}>
      {
        label &&
          <StatLabel>{label}</StatLabel>
      }
      <StatNumber>{stat}</StatNumber>
      {
        helperText &&
          <StatHelpText>{helperText}</StatHelpText>
      }
    </Stat>
  );
}
