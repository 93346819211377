import './App.scss';

import { 
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch} from 'react-router-dom';

import { Workout} from './features/workout';
import { CompletedWorkouts } from './pages/completedWorkouts';
import { Workout as CompletedWorkout } from './pages/completedWorkouts/workout';
import { Home } from './pages/home';

const App: React.FC = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/NewWorkout">
            <Workout />
          </Route>
          <Route exact path="/CompletedWorkouts">
            <CompletedWorkouts />
          </Route>
          <Route exact path="/CompletedWorkouts/:id" component={CompletedWorkout}/>
          <Route render={() => <Redirect to="/" />}/>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
