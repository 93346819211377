import { Set as WorkoutSet} from "../../types";

export interface TopExerciseGroup {
  name: string;
  value: number;
}

export interface Statistics {
  numberWorkouts: number;
  averageWeight: number;
  averageReps: number;
  averageSets: number;
}

export const topExerciseGroups = (sets: WorkoutSet[]): TopExerciseGroup[] => {
  const topExerciseGroups: {[key: string]: TopExerciseGroup} = {};

  sets.forEach((set) => {
    if (set.exercise.group) {
      if (topExerciseGroups[set.exercise.group]) {
        topExerciseGroups[set.exercise.group].value++;
      } else {
        topExerciseGroups[set.exercise.group] = {
          name: set.exercise.group,
          value: 1
        }
      }
    }
  });

  return Object.values(topExerciseGroups).sort((a, b) => b.value - a.value);
}

export const statisticsData = (sets: WorkoutSet[]): Statistics => {
  const statistics: Statistics = {
    numberWorkouts: 0,
    averageWeight: 0,
    averageReps: 0,
    averageSets: 0
  };
  let totalWeight = 0;
  let totalReps = 0;
  const totalSets = sets.length;
  const workoutIds = new Set<string>();
  
  sets.forEach((set) => {
    totalWeight += set.weight;
    totalReps += set.reps;
    workoutIds.add(set.workoutId);
  });

  statistics.numberWorkouts = workoutIds.size;
  statistics.averageWeight = totalWeight / totalSets;
  statistics.averageSets = totalSets / statistics.numberWorkouts;
  statistics.averageReps = totalReps / totalSets;

  return statistics;
}
