import './styles.scss';

import { Button, IconButton } from '@chakra-ui/button';
import { ArrowBackIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { HStack, VStack } from '@chakra-ui/layout';
import { Collapse, Stat, StatHelpText, StatLabel, StatNumber } from '@chakra-ui/react';
import { Select } from '@chakra-ui/select';
import React, { useState } from 'react';
import { IoAddCircleOutline as AddIcon } from "react-icons/io5";

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Set } from '../set';
import {
  addSet,
  changeExercise,
  Exercise as ExerciseType
} from '../workout/workoutSlice';
import { selectExercises } from './exercisesSlice';

interface ExerciseProps {
  index: number;
  exercise: ExerciseType;
  close: () => void;
  disabled?: boolean;
}

export const Exercise: React.FC<ExerciseProps> = ({ index, exercise, close, disabled = false }) => {
  const exercises = useAppSelector(selectExercises);
  const dispatch = useAppDispatch();
  const [showStats, setShowStats] = useState(false);

  const handleExerciseChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    exercises.forEach((exerciseGroup) => {
      exerciseGroup.exercises?.forEach((value) => {
        if(value.id === event.target.value){
          dispatch(changeExercise({exerciseIndex: index, exercise: value}));
        }
      })
    })
  }

  const toggleShowStats = () => {
    setShowStats(!showStats);
  }

  return (
    <>
      <VStack
        className={`exercise ${exercise.exercise?.group?.toLowerCase()}`}
        spacing={4}
        justify="center"
        width="100%">
        <IconButton
          onClick={close}
          icon={<ArrowBackIcon/>}
          aria-label='back'
          variant='ghost'
          alignSelf='flex-start'/>
        <HStack
          align="flex-end"
          justify="center"
          width="100%">
          { disabled ?
              <h2
                className='exercise-name'>
                {exercise?.exercise?.name}
              </h2>
              :
              <Select
                placeholder="Select an exercise"
                isFullWidth
                onChange={handleExerciseChange}
                value={exercise?.exercise?.id}
                whiteSpace="nowrap"
                disabled={disabled}>
                {
                  exercises.map((exerciseGroup, exerciseGroupIndex) => {
                    return (
                      <optgroup key={exerciseGroupIndex} label={exerciseGroup.group}>
                        {
                          exerciseGroup.exercises?.map((exerciseMap) => {
                            return <option key={exerciseMap.id} value={exerciseMap.id}>{exerciseMap.name}</option>
                          })
                        }
                      </optgroup>
                    )
                  })
                }
              </Select>
          }
          {
            exercise.exercise?.bestSet &&
            <IconButton
              variant="ghost"
              icon={<ChevronDownIcon/>}
              aria-label="Show Stats"
              onClick={toggleShowStats}
              />
          }
        </HStack>
        <Collapse
          in={showStats}
          animateOpacity
          className="set-stats-container">
          <HStack
            width="100%">
            {
              exercise.exercise?.bestSet &&
              <Stat>
                  <StatLabel>Best Set</StatLabel>
                  <StatNumber>{exercise.exercise?.bestSet?.weight} kg</StatNumber>
                  <StatHelpText>{exercise.exercise?.bestSet?.reps} Reps</StatHelpText>
                </Stat>
            }
            {
              exercise.exercise?.lastSet &&
              <Stat>
                  <StatLabel>Last Set</StatLabel>
                  <StatNumber>{exercise.exercise?.lastSet?.weight} kg</StatNumber>
                  <StatHelpText>{exercise.exercise?.lastSet?.reps} Reps</StatHelpText>
                </Stat>
            }
          </HStack>
        </Collapse>
      </VStack>
      {
        exercise.sets.length > 0 &&
        <VStack
          className="sets-container"
          padding={30}
          paddingTop="20px"
          spacing={4}
          justify="flex-start">
          <HStack
            justify="flex-start"
            w="100%">
            <VStack
              align="flex-start"
              w="100%">
              <p>Weight</p>
            </VStack>
            <VStack
              align="flex-start"
              w="100%">
              <p>Reps</p>
            </VStack>
            { !disabled &&
                <VStack>
                  <div className="placeholder" />
                </VStack>
            }
          </HStack>
          {
            exercise.sets.map((set, setIndex) => (
              <Set
                key={set.id}
                index={setIndex}
                exerciseIndex={index}
                set={set}
                disabled={disabled}/>
            ))
          }
          {
            !disabled &&
              <Button
                className='button'
                aria-label="Add Set"
                onClick={() => dispatch(addSet(index))}
                leftIcon={<AddIcon />}
                alignSelf='flex-end'>
                Set
              </Button>
          }
        </VStack>
      }
    </>
  );
}
