import './styles.scss';

import { Skeleton } from '@chakra-ui/react';
import React from 'react';

import { TopExerciseGroup } from '../../../helpers/HomeStatistics';

interface TopExerciseGroupsProps {
  exerciseGroupStats: TopExerciseGroup[] | undefined;
  loading: boolean;
}

export const TopExerciseGroups: React.FC<TopExerciseGroupsProps> = ({exerciseGroupStats, loading}) => {

  return (
    <div
      className='container exercise-groups'>
      <h2 className='header'>Top Exercise Groups</h2>
      <h5 className='sub-header'>Last 7 Days</h5>
      <div
        className='exercise-groups'>
        {
          (loading || !exerciseGroupStats) ? (
            <>
              <Skeleton
                w="100%"
                minW="220px"
                height="150px"
                borderRadius="8px"/>
              <Skeleton
                w="100%"
                minW="220px"
                height="150px"
                borderRadius="8px"/>
              <Skeleton
                w="100%"
                minW="220px"
                height="150px"
                borderRadius="8px"/>
            </>
          ) :
          exerciseGroupStats?.map((exerciseGroupStat, index) => (
            <div
              className='exercise-group'>
              <div className='position'>{index + 1}</div>
              <div
                className='information'>
                <h2 className='title'>{exerciseGroupStat.name}</h2>
                <p className='value'>{exerciseGroupStat.value}</p>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}
